import { Controller } from "@hotwired/stimulus"
import { patch, get } from '@rails/request.js'

export default class extends Controller {

  static values = { 
    id: Number,
    completed: Boolean,
    url: String,
    responseKind: {
      type: String,
      default: 'json'
    },
  }

  connect() {
  }

  async toggle(event) {
    this.element.classList.toggle("completed");

    const data = JSON.stringify({ todo: { completed: !this.completedValue } })
    const response = await patch(this.urlValue, { body: data, responseKind: this.responseKindValue })
    if (response.ok) {

      if(!this.completedValue) {
        const todos = document.querySelector('.todos.completed')
        todos.prepend(this.element)
      } else {
        const todos = document.querySelector('.todos.remaining')
        todos.append(this.element)
      }
      this.completedValue = !this.completedValue
    }

  }

  show(event) {
    Turbolinks.visit(this.urlValue)
  }

  edit(event) {
    Turbolinks.visit(this.urlValue+'/edit')
  }
  
}
