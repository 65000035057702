import { Controller } from "@hotwired/stimulus"

const fire = (obj, name, data) => {
  const event = new CustomEvent(
    name, {
    bubbles: true,
    cancelable: true,
    detail: data
  }
  )
  obj.dispatchEvent(event)
  return !event.defaultPrevented
}

export default class extends Controller {

  static targets = ["form", "content", "switch"]
 
  connect() {
    this.displayContent();
  }

  // ToDo: https://dev.to/nejremeslnici/migrating-from-jquery-ujs-to-rails-ujs-k9m
  onPostSuccess(event) {
    console.log("Success")
  }

  displayContent() {
    if (this.switchTarget.checked) {
      this.contentTarget.classList.remove("hide");
    } else {
      this.contentTarget.classList.add("hide");
    }
  }
  update() {
    fire(this.formTarget, 'submit');
    this.displayContent();
  }
 
}
