import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "input" ]

  static values = { 
    content: String
  }

  copy() {
    this.inputTarget.value = this.contentValue
  }
}
