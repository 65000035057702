import { Controller } from "@hotwired/stimulus"
import { patch, get } from '@rails/request.js'

export default class extends Controller {

  static values = { 
    id: Number,
    flag: Number,
    url: String,
    prefix: {
      type: String,
      default: 'flag'
    },
    responseKind: {
      type: String,
      default: 'json'
    },
  }

  connect() {
    this.toggleClass();
  }

  toggleClass() {
    this.element.classList.remove(`${this.prefixValue}0`)
    this.element.classList.remove(`${this.prefixValue}1`)
    this.element.classList.remove(`${this.prefixValue}2`)
    this.element.classList.add(`${this.prefixValue}${this.flagValue}`);
  }

  updateFlag() {
    this.flagValue = this.flagValue + 1
    if (this.flagValue > 2) {
      this.flagValue = 0
    }
  }

  async update(event) {
    this.updateFlag()

    const data = JSON.stringify({ improvement: { workflows_attributes: {0: { flag: this.flagValue, id: this.idValue } } } })
    const response = await patch(this.urlValue, { body: data, responseKind: this.responseKindValue })
    if (response.ok) {
      this.toggleClass()
    } else {
      alert('Er is iets fout gegaan!')
    }
  }
}
