import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  success(event) {
    $(event.target.parentElement).effect('highlight', 1000)
  }

  error(event) {
    alert('Er is iets fout gegaan! Het antwoord is niet opgeslagen.')
    $(event.target.parentElement).effect('highlight', {color: '#F2DEDE'}, 2000)
  }

  onPostSuccess(event) {
    $(event.target.parentElement).effect('highlight', 1000)
  }

  onPostError(event) {
    alert('Er is iets fout gegaan!')
    $(event.target.parentElement).effect('highlight', {color: '#F2DEDE'}, 2000)
  }

  submit() {
    Rails.fire(this.element, 'submit');
  }
}
