import { Controller } from "@hotwired/stimulus"

export default class extends Controller {


  connect() {
    console.log('todos')
  }

  completed() {
    console.log('todos completed')
  }

  
}
