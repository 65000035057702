import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["checkbox", "label"]

  static values = { 
    id: Number
  }

  connect() {
    this.hideCheckbox()
    this.switchClassList()
  }

  hideCheckbox() {
    this.checkboxTarget.style.position = 'absolute'
    this.checkboxTarget.style.left = '-9999px'
  }

  click() {
    this.toggle()
    this.switchClassList()
  }

  toggle() {
    this.checkboxTarget.checked = !this.checkboxTarget.checked
  }

  switchClassList() {
    this.labelTarget.classList.remove('btn-default');
    this.labelTarget.classList.remove('btn-warning');
    if (this.checkboxTarget.checked) {
      this.labelTarget.classList.add('btn-warning');
    }
    else {
      this.labelTarget.classList.add('btn-default');
    }       
  }

}
