import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["list", "comment"]

  static values = { 
    id: Number,
    url: String
  }
 
  connect() {
  }

  createSuccess(e) {
    let [data, status, xhr] = e.detail;
  
    this.listTarget.innerHTML = xhr.response + this.listTarget.innerHTML
    this.commentTarget.value = ''
    this.commentTarget.style.height = 'auto'
  }

  edit(event) {
    Turbolinks.visit(this.urlValue+'/edit')
  }
 
}
