import { Controller } from "@hotwired/stimulus"
import { patch, get } from '@rails/request.js'

export default class extends Controller {

  static targets = [ "switch" ]

  static values = { 
    id: Number,
    intern: Boolean,
    url: String
  }

  switchTargetConnected () {
    if (this.internValue) {
      this.switchTarget.classList.add('fa-toggle-off');
    }
    else {
      this.switchTarget.classList.add('fa-toggle-on');
    }

  }

  connect() {
    this.switchClassList()
  }

  switchClassList() {
    this.element.classList.remove('text-body-tertiary');
    this.switchTarget.classList.remove('fa-toggle-on', 'fa-toggle-off', 'text-success');
    if (this.internValue) {
      this.element.classList.add('text-body-tertiary');
      this.switchTarget.classList.add('fa-toggle-off');
    } else {
      this.switchTarget.classList.add('fa-toggle-on', 'text-success');
    }
  }

  async toggle(event) {
    const data = JSON.stringify({ workflow_comment: { intern: !this.internValue } })
    const response = await patch(this.urlValue, { body: data, responseKind: 'json' })
    if (response.ok) {
      response.json.then((data) => {
        this.internValue = data.intern
        this.switchClassList()
      })
    }
  }
}
