import { Controller } from "@hotwired/stimulus"
import { patch, get } from '@rails/request.js'

export default class extends Controller {
  static targets = ["planning", "later", "fork", "visibility"]
  static values = {
    improvementId: Number,
    url: String,
    related: Number,
    visible: Boolean,
    improvableType: String,
    improvableId: Number
  }

  connect() {
    this.refresh()
  }

  refresh() {
    this.refreshFork()
    this.refreshVisibility()
  }

  refreshFork() {
    if (this.hasForkTarget) {
      if (this.allowFork()) {
        this.forkTarget.style.display = "inline";
      } else {
        this.forkTarget.style.display = "none";
      }
    }
  }

  refreshVisibility() {
    if (this.hasVisibilityTarget) {
      if (this.allowVisibility()) {
        this.visibilityTarget.style.display = "inline";
      } else {
        this.visibilityTarget.style.display = "none";
      }
    }
  }

  refreshVisible() {
    if (this.hasVisibleValue) {
      if (this.visibleValue) {
        this.element.style = "";
      } else {
        this.element.style.display = "none";
      }
    }
  }

  allowFork() {
    return this.visibleValue && this.unplanned() && !this.related() && !this.improvableIsA('Target') 
  }

  allowVisibility() {
    return !this.related() && !this.improvableIsA('Target')
  }

  related() {
    return this.relatedValue > 0
  }

  planned() {
    return !this.unplanned()
  }

  unplanned() {
    return this.planningChecked.length === 0 && !this.laterTarget.checked 
  }

  improvableIsA(value) {
    return this.improvableTypeValue === value
  }

  visibleValueChanged() {
    this.refreshVisible()
  }

  toggle(event) {
    this.refresh()

    const plan_ids = this.planningChecked.map(x => x.value)
    const data = JSON.stringify({ improvement: { plan_ids: plan_ids } })
    this.update(data)
  }

  later(event) {
    const planning = this.laterTarget.checked ? 'later' : ''
    const data = JSON.stringify({ improvement: { planning: planning } })
    this.update(data)
  }

  async update(data) {
    const response = await patch(this.urlValue, { body: data, responseKind: 'json' })
    if (response.ok) {
      response.json.then(data => {
        this.element.dataset.planning = data.planning;
      })
      this.refresh()
      $(this.element).find('td').effect('highlight', 1000)
    } else {
      this.refresh()
      alert('Er is iets fout gegaan!')
    }
  }

  get planningChecked() {
    return this.planningTargets.filter((checkbox) => checkbox.checked)
  }

  get planningUnchecked() {
    return this.planningTargets.filter((checkbox) => !checkbox.checked)
  }
}
