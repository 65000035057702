import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form"]

  score(event) {
    Rails.fire(this.formTarget, "submit")
  }

  marked(event) {
    Rails.fire(this.formTarget, "submit")
  }

  remove(event) {
    Rails.fire(this.formTarget, "submit")
  }

  success(event) {
    $(event.target.parentElement).effect('highlight', 1000)
  }

  error(event) {
    alert('Er is iets fout gegaan! Het antwoord is niet opgeslagen.')
    this.formTarget.reset()
    $(event.target.parentElement).effect('highlight', {color: '#F2DEDE'}, 2000)
  }
}
